import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {Intercom} from 'ng-intercom';
import {AuthService} from './shared/auth/auth.service';
import {environment} from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  authUser: any;

  constructor(public intercom: Intercom, private authService: AuthService) {
    this.authUser = authService.getAuthUser();
  }

  ngOnInit() {
    if (environment.intercom.appId) {
      this.intercom.boot({
        app_id: environment.intercom.appId,
        name: this.authUser.firstName + ' ' + this.authUser.lastName, // Full name
        email: this.authUser.email, // Email address
        created_at: new Date(),
        widget: {
          'activator': '#intercom'
        }
      });
    }
  }

}
