import {Component, AfterViewChecked, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {AuthService} from "../auth/auth.service";
import {Observable} from 'rxjs';
import {PersonService} from '../services/person.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {browser} from 'protractor';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit,AfterViewChecked {
    currentLang = 'en';
    toggleClass = 'ft-maximize';
    placement = 'bottom-right';
    public isCollapsed = true;
    authUser;

    constructor(public translate: TranslateService,private authService:AuthService, private personService: PersonService, private router: Router) {
        const browserLang: string = translate.getBrowserLang();
        translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');
        this.authUser = this.authService.getAuthUser();
    }

    ngOnInit() {
      this.supplierValidator();
    }

    ngAfterViewChecked() {

        // setTimeout(() => {
        //     var wrapperDiv = document.getElementsByClassName("wrapper")[0];
        //     var dir = wrapperDiv.getAttribute("dir");           
        //     if (dir === 'rtl') {
        //         this.placement = 'bottom-left';
        //     }
        //     else if (dir === 'ltr') {
        //         this.placement = 'bottom-right';
        //     }
        // }, 3000);

        
    }


    ChangeLanguage(language: string) {
        this.translate.use(language);
    }

    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        }
        else
            this.toggleClass = 'ft-maximize'
    }

    signOut() {
      this.authService.logout()
    }

    selectedBusinessPartner: any;
    searchFailed = false;
    searching = false;
    dropdownFormatter = (result: any) => result.accountName + " | " + result.mobileNumber + " | " + result.officeNumber + " | " + result.companyName + " | " + result.city;
    inputFieldFormatter = (result: any) => {
      this.selectedPerson(result, "input");
      return result.accountName
    };
    searchPerson = (text$: Observable<string>) =>
      text$
        .debounceTime(300)
        .distinctUntilChanged()
        .do(() => {
          this.searching = true;
          this.selectedPerson(null, "clean")
        })
        .switchMap(term =>
          this.personService.searchPerson({name: term, type: "purchaseInvoice"}).do(response => {
            if (response) {
              this.searchFailed = false;
            } else {
              this.searchFailed = true;
              return Observable.of([]);
            }
          }).catch(() => {
            this.searchFailed = true;
            return Observable.of([]);
          }))
        .do(() => this.searching = false);

    selectedPerson(person, action) {
      if (action && action == "clean") {
        this.authService.setSelectedBusinessPartner(null);
        // this.selectedBusinessPartner = null;
        return true;
      }
      if (person && person.id) {
        if (this.authService.getSelectedBusinessPartner() && this.authService.getSelectedBusinessPartner().id && this.authService.getSelectedBusinessPartner().id == person.id){
          this.authService.setSelectedBusinessPartner(person);
          this.selectedBusinessPartner = person;
        } else {
          this.authService.setSelectedBusinessPartner(person);
          this.selectedBusinessPartner = person;
          this.router.navigate(['dashboard/dashboard1']);
        }
      }
    }

    supplierValidator() {
      if (this.authUser.permissions.includes('brand_portal_supplier_change')){
        if (this.authService.getSelectedBusinessPartner() && this.authService.getSelectedBusinessPartner().id){
          this.selectedPerson(this.authService.getSelectedBusinessPartner(), "input");
        }else {
          this.router.navigate(['dashboard/dashboard1']);
        }
        this.authService.selectedBusinessPartnerEmitter.asObservable().subscribe(
          response => {
            if (!response){
              this.router.navigate(['dashboard/dashboard1']);
            }
          }
        );
      }
    }

}
