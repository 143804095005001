import {Product} from './product.model';
import {InventoryCarton} from "./inventory-carton.model";

export class Inventory{
  id:number;
  date:any;
  comboProductId:number;
  productId:number;
  parentId:number;
  goodReceivedNoteId:number;
  goodReceivedNoteItemId:number;
  deliveryOrderId:number;
  deliveryOrderItemId:number;
  salesInvoiceId:number;
  stockId:number;
  uom:string;
  unitPrice:number;
  quantity:number;
  type:string;
  serialNo:string;
  isActive:boolean;
  status : any;
  costCenter:any;
  product:Product;

  inventoryCarton:InventoryCarton;
  inventoryCartonId:number;

  constructor(productId?:number){
    this.product = new Product();
    this.isActive = true;
    this.productId = productId ? productId : null;
  }
}


export class InventoryItem {
  public productId: number;
  public itemSerial: string;
}
