import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-view-serial',
  templateUrl: './view-serial.component.html',
  styleUrls: ['./view-serial.component.scss']
})
export class ViewSerialComponent implements OnInit {

  viewSerials: boolean = false;
  itemLine: any = {};
  public showAllSerials: boolean = false;

  @Input() item: any;
  @Input()
  public set toggleSerial(toggleSerial: boolean) {
    this.toggleSerialsStatus(toggleSerial);
  }

  constructor() { }

  ngOnInit() {
    this.itemLine = this.item;
    // if (!this.record.inventoryCartons || this.record.inventoryCartons.legnth == 0){
    //   this.record.inventoryCartons = GenerateInventorySerialFlatDataToObject(this.record.inventorySerials, this.record.product);
    // }
  }


  private toggleSerialsStatus(toggleSerial: boolean) {
    this.viewSerials = toggleSerial;
  }
}


