import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {NgxPermissionsService} from 'ngx-permissions';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';


@Injectable()
export class InitializerService {

  findUserPermissionsResponse: any;

  private token: string;
  private keycloakProfile: any;
  private authData: any;
  private authUser: any;
  private company: any;
  private companyConfiguration: any;
  private userManagementService: any;

  constructor(private keycloakService: KeycloakService, private permissionService: NgxPermissionsService, private http: HttpClient) {
    this.userManagementService = environment.serviceUrl;
  }

  load(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {

      const keycloakConfig: any = environment.keycloakAuth;
      const refreshToken: string = localStorage.getItem('keycloak-refresh-token');

      if (refreshToken) {
        keycloakConfig.initOptions.refreshToken = refreshToken;
      }

      const promise = await this.keycloakService.init(keycloakConfig);

      if (promise) {
        localStorage.setItem('keycloak-refresh-token', this.keycloakService.getKeycloakInstance().refreshToken);
        this.token = await this.keycloakService.getToken();

        this.authUser = await this.keycloakService.loadUserProfile();

        const httpOptions = {
          headers: new HttpHeaders(),
          params: {}
        };

        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', 'Bearer ' + this.keycloakService.getKeycloakInstance().token);

        httpOptions.headers = headers;

        this.findUserPermissionsResponse = await this.http
          .get(this.userManagementService + 'v1/user-service/permission/inventory/role/user/' + this.authUser.id, httpOptions)
          .toPromise().catch(err => this.logout());

        const permissions = [];
        this.findUserPermissionsResponse.perm.forEach(i => permissions.push(i.name));
        this.permissionService.addPermission(permissions);
        this.authUser.permissions = permissions;

      }
      resolve(promise);
      return promise;
    });
  }

  updateToken() {
    this.keycloakService.updateToken();
    this.setToken();
  }

  async setToken() {
    this.token = await this.keycloakService.getToken();
  }

  getToken(): string {
    return this.token;
  }

  getKeycloakProfile(): any {
    return this.keycloakProfile;
  }

  getAuthData(): any {
    return this.authData;
  }

  getCompanyConfiguration(): any {
    return this.companyConfiguration;
  }

  getCompany(): any {
    return this.company;
  }

  getAuthUser() {
    return this.authUser;
  }

  logout() {
    this.keycloakService.logout();
    // return this.ngaaUserService.signout();
  }

  login() {
    this.keycloakService.login();
    // return this.ngaaUserService.signout();
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token
    return this.keycloakService.isTokenExpired();
  }

}

interface AuthRequest {
  user: any;
  company: any;
  companyConfiguration: any;
}
