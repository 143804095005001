import {Inventory} from "../models/inventory.model";
import {InventoryCarton} from "../models/inventory-carton.model";
import {ToastrService} from 'ngx-toastr';
import {ComboProduct, Product} from '../models/product.model';

export function RecordItemInventorySerialToCartonMapper(items:any) {
  // items.forEach(item => {
  //   item.inventoryCartons = GenerateInventorySerialFlatDataToObject(item.inventorySerials, item.product);
  // })
  return items;
}

// export function GenerateInventoryCarton(product): InventoryCarton {
//   let invCar = new InventoryCarton();
//   product.comboProducts.forEach(childProduct => {
//     invCar.childProducts.push(Object.assign({},childProduct));
//   });
//   return invCar;
// }

// export function GenerateInventorySerialFlatDataToObject(inventorySerials: Inventory[], product?: Product){
//
//   const inventoryCartons = [];
//   if (product && product.comboProduct){
//
//     for (let x = 0; x < inventorySerials.length; x++) {
//
//       let cartonInv = inventoryCartons.find(invCarton => invCarton.id == inventorySerials[x].inventoryCartonId);
//
//       if (!cartonInv){
//         if (!inventorySerials[x].inventoryCartonId){
//           inventoryCartons.push(Object.assign(inventorySerials[x].inventoryCarton, {cartonSerialNotAvailable: true, childProducts: GenerateInventoryCarton(product).childProducts}));
//         } else {
//           inventoryCartons.push(Object.assign(inventorySerials[x].inventoryCarton, {cartonSerialNotAvailable: false, childProducts: GenerateInventoryCarton(product).childProducts}));
//         }
//         cartonInv = inventoryCartons[inventoryCartons.length - 1];
//       }
//
//       let childProd: ComboProduct = cartonInv.childProducts.find(cp => cp.childProductId == inventorySerials[x].productId);
//
//       if (!childProd.itemSerials){
//         childProd.itemSerials = [];
//       }
//       inventorySerials[x].inventoryCarton = null; // for avoid circular structure
//       childProd.itemSerials.push(Object.assign({},inventorySerials[x]));
//     }
//
//     console.log(inventoryCartons);
//
//   } else {
//     for (let x = 0; x < inventorySerials.length; x++) {
//       let cartonInv = inventoryCartons.find(invCarton => invCarton.id == inventorySerials[x].inventoryCartonId);
//       if (cartonInv){
//         cartonInv.itemSerials.push(Object.assign(new Inventory(), inventorySerials[x]));
//       } else {
//         if (!inventorySerials[x].inventoryCartonId){
//           inventoryCartons.push(Object.assign(new InventoryCarton(true),inventorySerials[x].inventoryCarton));
//         } else {
//           inventoryCartons.push(Object.assign(new InventoryCarton(),inventorySerials[x].inventoryCarton));
//         }
//         inventorySerials[x].inventoryCarton = null; // for avoid circular structure
//         inventoryCartons[inventoryCartons.length - 1].itemSerials = [inventorySerials[x]];
//       }
//     }
//   }
//
//   return inventoryCartons;
// }

export function CleanObject(inventorySerials: Inventory[]) {
  return $.extend( true, [], inventorySerials);
}

// export function ObjectValidateAndCorrection(inventoryCartons:InventoryCarton[], product:Product, type: 'STOCK_IN' | 'STOCK_OUT', cartonSerialNotAvailable: boolean, toastr: ToastrService): boolean {
//
//   if (product.comboProduct){
//      return ValidateWithComboProducts(inventoryCartons, product, type, cartonSerialNotAvailable, toastr);
//   } else {
//      return ValidateWithoutComboProducts(inventoryCartons, product, type, cartonSerialNotAvailable, toastr);
//   }
//
// }

// export function ValidateWithoutComboProducts(inventoryCartons:InventoryCarton[], product:Product, type: 'STOCK_IN' | 'STOCK_OUT', cartonSerialNotAvailable: boolean, toastr: ToastrService): boolean {
//
//   let isCartonSerialAvailable = false;
//   let isItemSerialAvailable = false;
//
//   if ((product.cartonSerialRequiredWhenStockIn && inventoryCartons.length == 0) || (product.cartonSerialRequiredWhenStockOut && inventoryCartons.length == 0)){
//     toastr.warning('Please enter carton serial keys', 'Warning!', {closeButton: true, timeOut: 4000});
//     return false;
//   }
//
//   for (let x = 0; x < inventoryCartons.length; x++) {
//
//     if (inventoryCartons[x].serialNo && inventoryCartons[x].serialNo.trim()) {
//       isCartonSerialAvailable = true;
//     }
//
//     if (type == 'STOCK_IN') {
//       if ((inventoryCartons.length == x + 1) && product.cartonSerialRequiredWhenStockIn && (!inventoryCartons[x].serialNo || !inventoryCartons[x].serialNo.trim())) {
//         toastr.warning('Please enter carton serial keys', 'Warning!', {closeButton: true, timeOut: 4000});
//         return false;
//       }
//     }
//     if (type == 'STOCK_OUT') {
//       if ((inventoryCartons.length == x + 1) && product.cartonSerialRequiredWhenStockOut && (!inventoryCartons[x].serialNo || !inventoryCartons[x].serialNo.trim())) {
//         toastr.warning('Please enter carton serial keys', 'Warning!', {closeButton: true, timeOut: 4000});
//         return false;
//       }
//     }
//
//     if ((product.itemSerialRequiredWhenStockIn && inventoryCartons[x].itemSerials.length == 0) || (product.itemSerialRequiredWhenStockOut && inventoryCartons[x].itemSerials.length == 0)){
//       toastr.warning('Please enter carton serial keys', 'Warning!', {closeButton: true, timeOut: 4000});
//       return false;
//     }
//
//     for (let y = 0; y < inventoryCartons[x].itemSerials.length; y++) {
//
//       if (inventoryCartons[x].itemSerials[y].serialNo && inventoryCartons[x].itemSerials[y].serialNo.trim()) {
//         isItemSerialAvailable = true;
//       }
//
//       if (type == 'STOCK_IN') {
//         if ((inventoryCartons[x].itemSerials.length == y + 1) && product.itemSerialRequiredWhenStockIn && (!inventoryCartons[x].itemSerials[y].serialNo || !inventoryCartons[x].itemSerials[y].serialNo.trim())) {
//           toastr.warning('Please enter carton serial keys', 'Warning!', {closeButton: true, timeOut: 4000});
//           return false;
//         }
//       }
//       if (type == 'STOCK_OUT') {
//         if ((inventoryCartons[x].itemSerials.length == y + 1) && product.itemSerialRequiredWhenStockOut && (!inventoryCartons[x].itemSerials[y].serialNo || !inventoryCartons[x].itemSerials[y].serialNo.trim())) {
//           toastr.warning('Please enter carton serial keys', 'Warning!', {closeButton: true, timeOut: 4000});
//           return false;
//         }
//       }
//     }
//   }
//
//   for (let x = 0; x < inventoryCartons.length; x++) {
//     if (isCartonSerialAvailable && (!inventoryCartons[x].serialNo || !inventoryCartons[x].serialNo.trim()) && (x < (inventoryCartons.length-1))) {
//       toastr.warning('Each carton must have ' + product.quantityInsideCarton + ' items', 'Warning!', {closeButton: true, timeOut: 4000});
//       return false;
//     }
//     if (isItemSerialAvailable && !cartonSerialNotAvailable) {
//
//       for (let y = 0; y < inventoryCartons[x].itemSerials.length; y++) {
//
//         if (!inventoryCartons[x].itemSerials[y].serialNo || !inventoryCartons[x].itemSerials[y].serialNo.trim()) {
//           toastr.warning('All item serials must be filled.', 'Warning!', {closeButton: true, timeOut: 4000});
//           return false;
//         }
//
//         if (product.quantityInsideCarton && (product.quantityInsideCarton != inventoryCartons[x].itemSerials.length)  && (x < (inventoryCartons.length-1))) {
//           let msg = '';
//           if (product.quantityInsideCarton > inventoryCartons[x].itemSerials.length) {
//             msg = 'Each carton must have ' + product.quantityInsideCarton + ' items. Only ' + (inventoryCartons[x].itemSerials.length) + ' items added.';
//           } else {
//             msg = 'Each carton can only have ' + product.quantityInsideCarton + ' items';
//           }
//           toastr.warning(msg, 'Warning!', {closeButton: true, timeOut: 4000});
//           return false;
//         }
//
//       }
//     }
//   }
//
//   return true;
// }

// export function ValidateWithComboProducts(inventoryCartons:InventoryCarton[], product:Product, type: 'STOCK_IN' | 'STOCK_OUT', cartonSerialNotAvailable: boolean, toastr: ToastrService): boolean {
//
//   let isCartonSerialAvailable = false;
//
//   if (((type == 'STOCK_IN' && product.cartonSerialRequiredWhenStockIn) || (type == 'STOCK_OUT' && product.cartonSerialRequiredWhenStockOut)) && (!inventoryCartons || inventoryCartons.length == 0)){
//     toastr.warning('Please enter carton serials', 'Warning!', {closeButton: true, timeOut: 4000});
//     return false;
//   }
//
//   for (let a = 0; a < inventoryCartons.length; a++) {
//     if (inventoryCartons[a].serialNo && inventoryCartons[a].serialNo.trim()) {
//       isCartonSerialAvailable = true;
//     }
//   }
//
//   for (let a = 0; a < inventoryCartons.length; a++) {
//
//     if ((isCartonSerialAvailable || (type == 'STOCK_IN' && product.cartonSerialRequiredWhenStockIn)) || (type == 'STOCK_OUT' && product.cartonSerialRequiredWhenStockOut)) {
//       if (!inventoryCartons[a].serialNo || !inventoryCartons[a].serialNo.trim()) {
//         toastr.warning('Please enter carton serial keys', 'Warning!', {closeButton: true, timeOut: 4000});
//         return false;
//       }
//     }
//
//     for (let b = 0; b < inventoryCartons[a].childProducts.length; b++) {
//
//       if (((type == 'STOCK_IN' && inventoryCartons[a].childProducts[b].childProduct.itemSerialRequiredWhenStockIn)
//         && (!inventoryCartons[a].childProducts[b].itemSerials || inventoryCartons[a].childProducts[b].itemSerials.length == 0))
//         ||
//         ((type == 'STOCK_OUT' && inventoryCartons[a].childProducts[b].childProduct.itemSerialRequiredWhenStockOut)
//           && (!inventoryCartons[a].childProducts[b].itemSerials || inventoryCartons[a].childProducts[b].itemSerials.length == 0))
//       ){
//         toastr.warning('Please enter item serial keys', 'Warning!', {closeButton: true, timeOut: 4000});
//         return false;
//       }
//
//       let itemSerialAvailable = false;
//
//       for (let c = 0; c < inventoryCartons[a].childProducts[b].itemSerials.length; c++) {
//         if ((!inventoryCartons[a].childProducts[b].itemSerials[c].serialNo || !inventoryCartons[a].childProducts[b].itemSerials[c].serialNo.trim())) {
//         }else {
//           itemSerialAvailable = true;
//         }
//       }
//
//       if (itemSerialAvailable ||
//         (type == 'STOCK_IN' && inventoryCartons[a].childProducts[b].childProduct.itemSerialRequiredWhenStockIn) ||
//         (type == 'STOCK_OUT' && inventoryCartons[a].childProducts[b].childProduct.itemSerialRequiredWhenStockOut) ){
//
//         if (!inventoryCartons[a].childProducts[b].itemSerials || inventoryCartons[a].childProducts[b].itemSerials.length == 0){
//           toastr.warning('Please enter item serials', 'Warning!', {closeButton: true, timeOut: 4000});
//           return false;
//         }
//
//         for (let c = 0; c < inventoryCartons[a].childProducts[b].itemSerials.length; c++) {
//           if ((!inventoryCartons[a].childProducts[b].itemSerials[c].serialNo || !inventoryCartons[a].childProducts[b].itemSerials[c].serialNo.trim())) {
//             toastr.warning('Please enter item serials', 'Warning!', {closeButton: true, timeOut: 4000});
//             return false;
//           }
//         }
//       }
//
//       if (itemSerialAvailable && inventoryCartons[a].childProducts[b].itemSerials.length < inventoryCartons[a].childProducts[b].quantity){
//         toastr.warning('Please enter all item serials', 'Warning!', {closeButton: true, timeOut: 4000});
//         return false;
//       }
//
//     }
//
//     return true;
//
//   }
//
//
// }

// export function CountQuantity(inventoryCartons: InventoryCarton[], product: Product){
//
//   let quantity = 0;
//   if(product.comboProduct){
//     quantity = inventoryCartons.length;
//     console.log(quantity,inventoryCartons.length);
//   }else {
//     inventoryCartons.forEach((value) => {
//       if (value.itemSerials && value.itemSerials.length > 0) {
//         let noAnySerials = true;
//         value.itemSerials.forEach((value1, index) => {
//           if (value1.serialNo && value1.serialNo.trim()) {
//             noAnySerials = false;
//             quantity += 1;
//           }
//         });
//         if (noAnySerials) {
//           quantity += product.quantityInsideCarton;
//         }
//       } else {
//         quantity += product.quantityInsideCarton;
//       }
//     });
//   }
//   console.log(quantity);
//   return quantity;
// }

export function ValidateSerials(itemLine: any, inventorySerials:Inventory[],type: 'STOCK_IN' | 'STOCK_OUT', toastr: ToastrService) {
  if (itemLine.product.comboProduct){
    for (let i = 0; i < itemLine.product.comboProducts.length; i++) {
      const childProd = itemLine.product.comboProducts[i];
      if ((type == 'STOCK_IN' && !childProd.childProduct.itemSerialRequiredWhenStockIn) || (type == 'STOCK_OUT' && !childProd.childProduct.itemSerialRequiredWhenStockOut)) {
        continue;
      }

      let checkForEmptySerialIndex = inventorySerials.findIndex(y => (y.serialNo == null || y.serialNo == "") && (y.productId == childProd.childProductId));
      if(checkForEmptySerialIndex > -1){
        toastr.warning('Please scan a valid serial for ' + childProd.childProduct.itemCode, 'Warning!', {closeButton: true, timeOut: 4000});
        return false;
      }

      let scannedSerialCount = inventorySerials.filter(x => x.productId == childProd.childProductId).length;
      if ( scannedSerialCount < (childProd.quantity * itemLine.quantity)) {
        toastr.warning(((childProd.quantity * itemLine.quantity) - scannedSerialCount)+  ' more serials need for ' + childProd.childProduct.itemCode, 'Warning!', {closeButton: true, timeOut: 4000});
        return false;
      }else if (scannedSerialCount > (childProd.quantity * itemLine.quantity)){
        toastr.warning('Serials count exceeded for ' + childProd.childProduct.itemCode, 'Warning!', {closeButton: true, timeOut: 4000});
        return false;
      }
    }

  } else {

    if ((type == 'STOCK_IN' && !itemLine.product.itemSerialRequiredWhenStockIn) || (type == 'STOCK_OUT' && !itemLine.product.itemSerialRequiredWhenStockOut)) {
      return true;
    }

    let checkForEmptySerialIndex = inventorySerials.findIndex(y => (y.serialNo == null || y.serialNo == "") && (y.productId == itemLine.productId));
    if(checkForEmptySerialIndex > -1){
      toastr.warning('Please scan a valid serial for ' + itemLine.product.itemCode, 'Warning!', {closeButton: true, timeOut: 4000});
      return false;
    }

    if (inventorySerials.length < itemLine.quantity){
      toastr.warning((itemLine.quantity - inventorySerials.length)+  ' more serials need for ' + itemLine.product.itemCode, 'Warning!', {closeButton: true, timeOut: 4000});
      return false;
    }else if (inventorySerials.length > itemLine.quantity){
      toastr.warning('Serials count exceeded for ' + itemLine.product.itemCode, 'Warning!', {closeButton: true, timeOut: 4000});
      return false;
    }

  }
  return true;

}
