import {EventEmitter, Injectable} from '@angular/core';
import {InitializerService} from "./initializer.service";

@Injectable()
export class AuthService {

  private selectedBusinessPartner = null;
  public selectedBusinessPartnerEmitter = new EventEmitter(null);

  constructor(private initializerService:InitializerService) {
    let selectedBusinessPartnerStr = localStorage.getItem("selectedBusinessPartnerData");
    if (selectedBusinessPartnerStr){
      this.setSelectedBusinessPartner(JSON.parse(selectedBusinessPartnerStr));
    }
  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email?: string, password?: string) {
    //your code for checking credentials and getting tokens for for signing in user
    this.initializerService.login();
  }

  logout() {
    return this.initializerService.logout();
  }

  getToken() {
    return this.initializerService.getToken();
  }

  refreshToken() {
    return this.initializerService.updateToken();
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token
    return this.initializerService.isAuthenticated();
  }

  getAuthUser(){
    return this.initializerService.getAuthUser();
  }

  login() {
    return this.initializerService.login();
    // return this.ngaaUserService.signout();
  }

  getSelectedBusinessPartner() {
    return this.selectedBusinessPartner;
  }

  setSelectedBusinessPartner(selectedBusinessPartner) {
    localStorage.setItem("selectedBusinessPartnerData", selectedBusinessPartner ? JSON.stringify(selectedBusinessPartner) : null);
    this.selectedBusinessPartner = selectedBusinessPartner;
    this.selectedBusinessPartnerEmitter.emit(this.selectedBusinessPartner);
  }

}
