import {Inventory} from './inventory.model';
import {InventoryCarton} from './inventory-carton.model';

export class Product {
  id: number;
  itemCode: any;
  itemSubCode: string;
  itemName: any;
  itemType: string;
  brandId: number;
  subItemId: number;
  subItemQuantity: number;
  uom: string;
  barcode: string;
  stockType: string;
  productType: string;
  itemLength: number;
  itemWidth: number;
  itemHeight: number;
  itemWeight: number;
  maxLevel: number;
  securityMargin: number;
  reorderLevel: number;
  asin: string;
  productOnlineItems: OnlineItems[];
  toBeRemovedProductOnlineItems: OnlineItems[];
  // serialNumber: string;

  saleAccountId: number;
  saleDescription: string;
  saleUnitPrice: number;
  saleDiscountCategoryId: number;
  saleTaxCategoryId: number;

  purchaseAccountId: number;
  purchaseTaxCategoryId: number;
  purchaseDescription: string;
  purchaseDiscountCategoryId: number;
  purchaseUnitPrice: number;

  sellingItem: boolean;
  purchasingItem: boolean;
  isOpeningQuantityAvailable: boolean;

  unitPrice: number;
  amount: any;
  quantity: number;

  productImages: any[];
  toBeSavedProductImages: any[];
  productBrand: ProductBrand;
  // productCategory: ProductCategory;

  assemblyItems: NewProductRow[];
  manufactureQty: number;
  isActive: any;
  itemSerialRequiredWhenStockIn: boolean;
  itemSerialRequiredWhenStockOut: boolean;
  cartonSerialRequiredWhenStockIn: boolean;
  cartonSerialRequiredWhenStockOut: boolean;
  cartonSku: string;
  quantityInsideCarton: number;
  comboProduct: boolean;
  comboProducts: ComboProduct[];
  toBeRemovedComboProducts: ComboProduct[];

  invoiceAsCartonsInPurchaseOrder: boolean;
  purchaseCartonPrice: number;

  constructor() {
    this.assemblyItems = [];
    this.manufactureQty = 1;
    this.toBeSavedProductImages = [];
    // this.productBrand = new ProductBrand();
    this.toBeRemovedProductOnlineItems = [];
    this.productOnlineItems = [];
    this.productOnlineItems.push(new OnlineItems());
    this.isActive = true;
    this.itemSerialRequiredWhenStockIn = false;
    this.itemSerialRequiredWhenStockOut = false;
    this.cartonSerialRequiredWhenStockIn = false;
    this.cartonSerialRequiredWhenStockOut = false;
    this.comboProduct = false;
    this.comboProducts = [];
    this.comboProducts.push(new ComboProduct());
    this.comboProducts.push(new ComboProduct());
    this.toBeRemovedComboProducts = [];
  }
}

export class NewProductRow {
  rawProductId: number;
  productId: number;
  uom: string;
  quantity: number;
  singleUnitQTY: number;
}

export class OnlineItems {
  id: number;
  productId: number;
  apiConfigurationId: string;
  onlineItemId: string;
  shopifyOnlineProductId: number;
  shopifyVariants: any[];
  apiConfiguration: any;
}

export class ComboProduct {
  id: number;
  productId: number;
  childProductId: number;
  quantity: number;
  childProduct: Product;
  itemSerials: Inventory[] = [];
}

export class ProductBrand {
  id: number;
  brandName: string;
  description: string;
}

export class ProductCategory {
  id: number;
  categoryName: string;
  description: string;
  parentId: number;
}


export class SearchProduct {
  id: number;
  itemCode: string;
  itemName: string;
  itemBarcode: string;
  brandId: number;
}
