import {RouteInfo} from './sidebar.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

  {
    path: '/dashboard/dashboard1',
    title: 'Dashboard',
    icon: 'ft-home',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    submenu: []
  },

  // {
  //   path: '', title: 'Purchase', icon: 'icon-basket-loaded', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //   submenu: [
  //     {
  //       path: '',
  //       title: 'Purchase Order',
  //       icon: '',
  //       class: 'has-sub',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       submenu: [
  //         {
  //           path: '/purchase-order/purchase-order/PRIOR_ORDER_PO',
  //           title: 'Prior PO',
  //           icon: '',
  //           class: '',
  //           badge: '',
  //           badgeClass: '',
  //           isExternalLink: false,
  //           submenu: []
  //         },
  //         {
  //           path: '/purchase-order/purchase-order/INVOICE_ORDER_PO',
  //           title: 'Invoice Order PO',
  //           icon: '',
  //           class: '',
  //           badge: '',
  //           badgeClass: '',
  //           isExternalLink: false,
  //           submenu: []
  //         },
  //         {
  //           path: '/purchase-order/purchase-order/CONSIGNMENT_PO',
  //           title: 'Consignment PO',
  //           icon: '',
  //           class: '',
  //           badge: '',
  //           badgeClass: '',
  //           isExternalLink: false,
  //           submenu: []
  //         }
  //       ]
  //     },
  //     {
  //       path: '/good-received-note/good-received-note',
  //       title: 'Good Received Note',
  //       icon: '',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       submenu: []
  //     }
  //   ]
  // },

  {path: '/purchase-invoice-settlement/list', title: 'Settlements', icon: 'fa fa-file-text-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
  {path: '/send-inventory/list', title: 'Send Inventory', icon: 'fa fa-file-text-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},

  // {
  //   path: '', title: 'Invoice Settlement', icon: 'fa fa-file-text-o', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //   submenu: [
  //     {path: '/purchase-invoice/create-purchase-invoice', title: 'Client Invoices Upload', icon: '', class: '', badge: '', badgeClass: '',isExternalLink: false,submenu: []},
  //     {path: '/purchase-invoice/consignment-invoice-upload', title: 'Consignment Invoice Upload', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
  //     {path: '/purchase-invoice/purchase-invoice', title: 'Client Invoices', icon: '', class: '', badge: '', badgeClass: '',isExternalLink: false,submenu: []},
  //     {path: '/purchase-invoice-settlement/create', title: 'New Settlement', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
  //     {path: '/purchase-invoice-settlement/list', title: 'Settlements', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
  //   ]
  // },

  {
    path: '', title: 'Sales', icon: 'icon-docs', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      // { path: '/delivery-order/delivery-order', title: 'Delivery Order', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      {
        path: '/sales-invoice/sales-invoice',
        title: 'Shipments',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/sales-invoice/create-sales-invoice',
        title: 'Create Shipment',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      // {
      //   path: '/sales-invoice/upload-sale-invoice-excel',
      //   title: 'Upload Excel',
      //   icon: '',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false,
      //   submenu: []
      // },

      // { path: '/receipt/receipt', title: 'Receipt', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },

  {
    path: '/return-ticket/return-tickets-list',
    title: 'Returns',
    icon: 'ft-rotate-ccw',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: []
  },

  // {
  //   path: '', title: 'Sales Return', icon: 'ft-rotate-ccw', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //   submenu: [
  //     {
  //       path: '/return-ticket/return-tickets-list',
  //       title: 'Return Tickets',
  //       icon: '',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/credit-note/credit-note-list',
  //       title: 'Credit Notes/Refunds',
  //       icon: '',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/return-ticket/manual-return-ticket-create',
  //       title: 'Manual Return Ticket',
  //       icon: '',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/return-ticket/shipping-claims-list',
  //       title: 'Shipping Claims',
  //       icon: '',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       submenu: []
  //     },
  //   ]
  // },

  // {
  //   path: '/sales-invoice/sales-invoice-process',
  //   title: 'Ship List',
  //   icon: 'fa fa-truck',
  //   class: '',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   submenu: []
  // },

  // {
  //   path: '', title: 'RMA', icon: 'fa fa-backward', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //   submenu: [
  //     { path: '/rma/rma', title: 'RMA', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //     { path: '/rma/create-rma', title: 'Create RMA', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //   ]
  // },

  // {
  //   path: '', title: 'Organization', icon: 'fa fa-building-o', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //   submenu: [
  //     { path: '/organization/create', title: 'Create Organization', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //     { path: '/organization/list', title: 'Organizations', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //   ]
  // },

  // {
  //   path: 'icon-layers', title: 'Accounting', icon: 'icon-pie-chart', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //   submenu: [

  //     {
  //       path: '', title: 'Cheque', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //       submenu: [
  //         { path: '/cheque/cheque-print', title: 'Cheque Print', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         { path: '/cheque/cheque-issued', title: 'Issued Cheque', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //         { path: '/cheque/cheque-deposit', title: 'Cheque Deposits', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //       ]
  //     },

  //     {
  //       path: '/tax', title: 'Tax Rates', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  //     },
  //
  //     {
  //       path: '/discount', title: 'Discounts', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  //     },
  //   ]
  // },


  // {
  //   path: '', title: 'Inventory', icon: 'ft-shopping-cart', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //   submenu: [
  //     {path: '/inventory/summary', title: 'Summary', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
  //     {path: '/inventory/list', title: 'List', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
  //     {path: '/inventory/amazon-fba-inventory', title: 'FBA Inventory', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
  //     // {path: '/manual-stock/manual-stock-list', title: 'Manual Stock Override', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []}
  //   ]
  // },

  // {
  //   path: '/test', title: 'Test root', icon: 'ft-shopping-cart', class: '', badge: '', badgeClass: '', isExternalLink: false,
  //   submenu: [
  //     // {path: '/test-root/test1', title: 'Test 1', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
  //     // {path: '/test-root/test2', title: 'Test 2', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
  //     // {path: '/test-root/test3', title: 'Test 3', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
  //   ]
  // },

  {
    path: '', title: 'Inventory Transfer', icon: 'ft-activity', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      {
        path: '/inventory-transfer/transfer-list',
        title: 'Transfers',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      // {
      //   path: '/inventory-transfer/transfer-new',
      //   title: 'New Transfer ',
      //   icon: '',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false,
      //   submenu: []
      // },
    ]
  },

  {
    path: '', title: 'Inventory Reports', icon: 'icon-bar-chart', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      {
        path: '/reports/stock-control-and-value',
        title: 'Vyper Warehouse Inventory',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {path: '/inventory/amazon-fba-inventory', title: 'Amazon FBA Inventory', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      {
        path: '/reports/return-report',
        title: 'Return Inventory',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      // {
      //   path: '/reports/daily-control-and-ship-list',
      //   title: 'Daily Control and Ship List',
      //   icon: '',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false,
      //   submenu: []
      // },
      // {
      //   path: '/reports/daily-inventory-level',
      //   title: 'Daily Inventory Level',
      //   icon: '',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false,
      //   submenu: []
      // },
    ]
  },

  // {
  //   path: '', title: 'API', icon: 'ft-cloud-lightning', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //   submenu: [
  //     {path: '/api/royal-mail', title: 'Royal Mail Sync', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
  //     {
  //       path: '/api/ebay-stock-update',
  //       title: 'Ebay Stock Update',
  //       icon: '',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/api/shopify-stock-update',
  //       title: 'Shopify Stock Update',
  //       icon: '',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/api/amazon-stock-update',
  //       title: 'Amazon Stock Update',
  //       icon: '',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/api/shipping-career-fulfillment',
  //       title: 'Shipping Fulfilment',
  //       icon: '',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/api/marketplace-fulfillment',
  //       title: 'Marketplace Fulfilment',
  //       icon: '',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/api/amazon-fulfillment',
  //       title: 'Amazon Fulfilment',
  //       icon: '',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       submenu: []
  //     }
  //   ]
  // },

  // {
  //   path: '/excel-mapping/upload-excel',
  //   title: 'Excel Mapping',
  //   icon: 'fa fa-file-excel-o',
  //   class: '',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   submenu: []
  // },

  {
    path: '', title: 'Catalogue', icon: 'icon-flag', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      {path: '/product/product', title: 'Product', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      // {path: '/product/brand', title: 'Brand', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      // {
      //   path: '/warehouse/warehouse',
      //   title: 'Warehouse',
      //   icon: '',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false,
      //   submenu: []
      // },
      //
      // {
      //   path: '/cost-center/cost-center',
      //   title: 'Cost Center',
      //   icon: '',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false,
      //   submenu: []
      // },
      // {
      //   path: '/person/people',
      //   title: 'Business Partners',
      //   icon: '',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false,
      //   submenu: []
      // },
      // {path: '/customer/customers', title: 'Customers', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
      // {
      //   path: '/api/api-configuration',
      //   title: 'Api Config',
      //   icon: '',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false,
      //   submenu: []
      // },
      // {
      //   path: '/log/system-log',
      //   title: 'System Logs',
      //   icon: '',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false,
      //   submenu: []
      // },
      // {
      //   path: '', title: 'Users', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
      //   submenu: [
      //     {
      //       path: '/usermanagement/permission',
      //       title: 'Permissions',
      //       icon: '',
      //       class: '',
      //       badge: '',
      //       badgeClass: '',
      //       isExternalLink: false,
      //       submenu: []
      //     },
      //     {
      //       path: '/usermanagement/role',
      //       title: 'Roles',
      //       icon: '',
      //       class: '',
      //       badge: '',
      //       badgeClass: '',
      //       isExternalLink: false,
      //       submenu: []
      //     },
      //     {
      //       path: '/usermanagement/user',
      //       title: 'Users',
      //       icon: '',
      //       class: '',
      //       badge: '',
      //       badgeClass: '',
      //       isExternalLink: false,
      //       submenu: []
      //     },
      //   ]
      // },
    ]
  },

  // {
  //   path: '', title: 'Branch', icon: 'icon-home', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //   submenu: [
  //     { path: '/branch/branch-create', title: 'Branch', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  //   ]
  // },
];
