import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { CustomizerComponent } from './customizer/customizer.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import {CustomBreadcrumbModule} from '../custom-breadcrumb/custom-breadcrumb.module';
import { SerialListComponent } from './serial-list/serial-list.component';
import {FormsModule} from '@angular/forms';
import { ViewSerialComponent } from './view-serial/view-serial.component';
import {NgxPermissionsModule} from "ngx-permissions";


@NgModule({
  exports: [
    CommonModule,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    CustomizerComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    NgbModule,
    TranslateModule,
    SerialListComponent,
    ViewSerialComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    CustomBreadcrumbModule,
    FormsModule,
    NgxPermissionsModule.forChild(),
  ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SerialListComponent,
        ViewSerialComponent
    ]
})
export class SharedModule { }
