import { Injectable } from '@angular/core';
import {Restangular} from "ngx-restangular";

@Injectable({providedIn: 'root'})
export class PersonService {

  constructor(private restangular : Restangular) { }

  getPersonsList(data){
    return this.restangular.one("person/persons-list").customPOST(data);
  }

  personDetails(id){
    return this.restangular.one("person").customGET("person-details",{id:id});
  }

  searchPerson(data){
    return this.restangular.one("person").customGET("search-person",data);
  }

  //suppliers
  getAllSuppliers(){
    return this.restangular.one("suppliers").customGET();
  }

  getSupplier(data){
    return this.restangular.one("suppliers",data).customGET();
  }

  //save person
  savePerson(personData){
    return this.restangular.one("person/save-person").customPOST(personData);
  }

  //Delete person
  getDeactivatePersonId(id){
    return this.restangular.one("person/deactivate").customPOST(id);
  }

  //Get Edit person's data
  getEditPersonDataId(id){
    return this.restangular.one("person/getPerson").customPOST(id);
  }

}
