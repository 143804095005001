import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';
import {ToastrModule} from 'ngx-toastr';
import {AgmCoreModule} from '@agm/core';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {StoreModule} from '@ngrx/store';
import {DragulaModule} from 'ng2-dragula';

import {AppComponent} from './app.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';
import {FullLayoutComponent} from './layouts/full/full-layout.component';

import {AuthService} from './shared/auth/auth.service';
import {AuthGuard} from './shared/auth/auth-guard.service';

import * as $ from 'jquery';
import {AppService} from './app.service';
import {InitializerService} from './shared/auth/initializer.service';
import {environment} from '../environments/environment';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {RestangularModule} from 'ngx-restangular';
import {PreviousRouteService} from './shared/routes/previous-route.service';
import {NgxPermissionsModule} from 'ngx-permissions';
import {PermissionGuard} from './shared/auth/permission-guard.service';
import {KeycloakGuard} from './shared/auth/keycloak-guard.service';
import {DROPZONE_CONFIG, DropzoneConfigInterface} from 'ngx-dropzone-wrapper';
import {BsDatepickerModule} from 'ngx-bootstrap';
import {PersistenceModule} from 'angular-persistence';
import {CustomBreadcrumbModule} from './custom-breadcrumb/custom-breadcrumb.module';
import {IntercomModule} from 'ng-intercom';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function RestangularConfigFactory(RestangularProvider, authService) {

  RestangularProvider.setBaseUrl(environment.baseUrl);
  // set static header
  RestangularProvider.setDefaultHeaders({'Authorization': 'Bearer ', 'Content-Type': 'application/json'});// Bearer UDXPx-Xko0w4BRKajozCVy20X11MRZs1

  // by each request to the server receive a token and update headers with it
  RestangularProvider.addFullRequestInterceptor((element, operation, path, url, headers, params) => {
    authService.refreshToken();
    let headersTemp: any = {};
    if (authService.getAuthUser().permissions.includes('brand_portal_supplier_change')) {
      if (authService.getSelectedBusinessPartner() && authService.getSelectedBusinessPartner().id) {
         headersTemp = {'X-Supplier': authService.getSelectedBusinessPartner().id.toString()};
      }
    }

    let bearerToken = authService.getToken();
    headersTemp = Object.assign(headersTemp, {'Authorization': 'Bearer ' + bearerToken});
    return {
      headers: Object.assign({}, headers, headersTemp)
    };
  });

  RestangularProvider.setErrorInterceptor((response) => {
    if (response.status == 401) {
      authService.logout();
    }
    return response;
  });

}

export function initializeConfig(config: InitializerService) {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      config.load().then(response => {
        resolve(response);
      });
    });
  };
}

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: environment.baseUrl + 'upload/uploadFile',
  addRemoveLinks: true,
  dictRemoveFile: 'Remove',
  maxFilesize: 10,
  acceptedFiles: 'image/*,application/pdf,.xlsx,.docx',
  clickable: true,
  createImageThumbnails: true,
};

export function randomString(length) {
  let text = '';
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent
  ],
  imports: [
    KeycloakAngularModule,
    BrowserAnimationsModule,
    PersistenceModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    SharedModule,
    DragulaModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot(),
    NgbModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBr5_picK8YJK7fFR2CPzTVMj6GG1TtRGo'
    }),
    RestangularModule.forRoot([AuthService], RestangularConfigFactory),
    BsDatepickerModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    CustomBreadcrumbModule,
    IntercomModule.forRoot({
      appId: environment.intercom.appId, // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    })
  ],
  providers: [
    InitializerService,
    {provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG},
    {
      provide: APP_INITIALIZER,
      useFactory: initializeConfig,
      multi: true,
      deps: [InitializerService]
    },
    AuthService,
    AuthGuard, KeycloakGuard,
    AppService, KeycloakService, PermissionGuard, PreviousRouteService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
