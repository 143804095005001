import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable({providedIn: 'root'})
export class ProductService {

  constructor(private restangular: Restangular) {
  }

  saveProduct(data) {
    if (data.id){
      return this.restangular.one('products').customPUT(data, data.id);
    } else {
      return this.restangular.one('products').customPOST(data);
    }
  }

  allProducts() {
    return this.restangular.one('products').customGET();
  }

  getProductList(data) {
    return this.restangular.one('products/list').customPOST(data);
  }

  getProductDetails(id) {
    return this.restangular.one('products').customGET(id);
  }

  getAllBrands() {
    return this.restangular.one('product-brands').customGET();
  }

  getAllCategoryTypesPaginated(data) {
    return this.restangular.one('product_category/all-paginated').customPOST(data);
  }

  getAllBrandsPaginated(data) {
    return this.restangular.one('product-brands/list').customPOST(data);
  }

  saveProductCategory(data) {
    return this.restangular.one('product_category/save').customPOST(data);
  }

  saveProductBrand(data) {
    return this.restangular.one('product-brands').customPOST(data);
  }

  updateProductBrand(data){
    return this.restangular.one('product-brands').customPUT(data,data.id);
  }
}
