import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomBreadcrumbRoutingModule } from './custom-breadcrumb-routing.module';
import { CustomBreadcrumbComponent } from './custom-breadcrumb/custom-breadcrumb.component';

@NgModule({
  imports: [
    CommonModule,
    CustomBreadcrumbRoutingModule,
  ],
  exports: [
    CustomBreadcrumbComponent
  ],
  declarations: [CustomBreadcrumbComponent]
})
export class CustomBreadcrumbModule { }
