import { Routes, RouterModule } from '@angular/router';
import {KeycloakGuard} from "../auth/keycloak-guard.service";

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
  {
    path: 'pages',
    loadChildren: './pages/content-pages/content-pages.module#ContentPagesModule'
  },
  // {
  //   path: 'good-received-note-content',
  //   loadChildren: './good-received-note/good-received-note.module#GoodReceivedNoteModule', canActivate: [KeycloakGuard]
  // },
  // {
  //   path: 'sales-invoice-content',
  //   loadChildren: './sales-invoice/sales-invoice.module#SalesInvoiceModule', canActivate: [KeycloakGuard]
  // },
  {
    path: 'reports-content',
    loadChildren: './reports/reports.module#ReportsModule', canActivate: [KeycloakGuard]
  },
];
