import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Inventory} from '../models/inventory.model';
import {ToastrService} from 'ngx-toastr';
import {InventoryCarton} from '../models/inventory-carton.model';
import {
  CleanObject,
  ValidateSerials
} from '../helpers/inventory-serial.helper';
import {Product} from '../models/product.model';
import {ProductService} from '../services/product.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-serial-list',
  templateUrl: './serial-list.component.html',
  styleUrls: ['./serial-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SerialListComponent implements OnInit, AfterViewInit {
  comboProduct: Product;
  inventoryCartons: InventoryCarton[] = [];
  inventorySerials: Inventory[] = [];
  isSerialRequiredForAnyProduct: boolean = false;

  @Input() public itemLine;
  @Input() public type: 'STOCK_IN' | 'STOCK_OUT';
  @Input() public validateQuantity: boolean;
  @Input() public hideHeaders: boolean;
  @Input() public hideActionButtons: boolean;
  @Input() public emitWhenQuantityFulfilled: boolean;

  @Input()
  set focusToEmptyOrLastSerial(value: boolean){
    if (value){
      console.log(this.isSerialRequiredForAnyProduct);
      if (this.isSerialRequiredForAnyProduct){
        this.serialInputHandler();
      } else {
        this.concurrentOutputSender(true);
        // this.showProductSwal(this.itemLine.product, this.itemLine.quantity).then(
        //   value1 => {
        //     this.concurrentOutputSender(true);
        //   }
        // );
      }
    }
  }

  @Output() inventoryOutput: EventEmitter<any> = new EventEmitter();
  @Output() concurrentOutput: EventEmitter<any> = new EventEmitter();

  constructor(private toastr: ToastrService, private productService: ProductService) { }

  ngAfterViewInit(): void {}

  ngOnInit() {
    console.log(this.itemLine);
    console.log(this.itemLine.product.comboProduct);
    this.itemLine = $.extend( true, {}, this.itemLine);
    this.inventorySerials = this.itemLine.inventorySerials;
    if (this.itemLine.product.comboProduct){
      this.comboProduct = this.itemLine.product;

      // if ((!this.itemLine.inventoryCartons || this.itemLine.inventoryCartons.length == 0) && this.itemLine.inventorySerials.length > 0) {
      //   this.inventoryCartons = GenerateInventorySerialFlatDataToObject(this.itemLine.inventorySerials);
      //   this.cartonSerialNotAvailable = this.inventoryCartons[0].cartonSerialNotAvailable;
      // } else if (this.itemLine.inventoryCartons && this.itemLine.inventoryCartons.length > 0) {
      //   this.inventoryCartons = Object.assign([],this.itemLine.inventoryCartons);
      //   console.log(this.inventoryCartons);
      //   // this.checkAndJumpToNextChildProduct(0,0,-1);
      // }else {
      //   this.getProduct(this.itemLine.product.id);
      // }

    }else {
      // this.inventorySerials.push(new Inventory(this.itemLine.productId));

      // if ((!this.itemLine.inventoryCartons || this.itemLine.inventoryCartons.length == 0) && this.itemLine.inventorySerials.length > 0) {
      //   this.inventoryCartons = GenerateInventorySerialFlatDataToObject(this.itemLine.inventorySerials);
      //   this.cartonSerialNotAvailable = this.inventoryCartons[0].cartonSerialNotAvailable;
      // } else if (this.itemLine.inventoryCartons && this.itemLine.inventoryCartons.length > 0) {
      //   this.inventoryCartonsObjectInitializer(this.itemLine.inventoryCartons);
      // }
    }

    this.serialInputHandler();

  }

  getProduct(id) {
    this.productService.getProductDetails(id).subscribe(
      response => {
        this.comboProduct = Object.assign(new Product(),response);
        this.inventoryCartons = [];
        // this.checkAndJumpToNextChildProduct(0,0,-1);
        console.log(this.comboProduct);
      }, err => console.log(err)
    );
  }

  serialNoScannerListener(serial, index) {
    console.log(serial, index);
    if (serial.serialNo == undefined) {
      return;
    }
    let value = serial.serialNo.trim();
    if (value == null || value == '') {
      serial.serialNo = null;
      setTimeout(() => { // this will make the execution after the above boolean has changed
        document.getElementById('item-serial-' + this.itemLine.id + '-' + index).focus();
      }, 1);
      this.concurrentOutputSender();
      return true;
    }

    for (let x = 0; x < this.inventorySerials.length; x++) {
      if (index != x && this.inventorySerials[x].serialNo == value) {
        this.toastr.warning('Serial already in the list', 'Warning!', {closeButton: true, timeOut: 4000});
        value = null;
        serial.serialNo = value;
        return;
      }
    }

    this.serialInputHandler(true);
    this.concurrentOutputSender(false);
  }


  serialInputHandler(showAlertForNonSerialProducts?:boolean){
    if (this.itemLine.product.comboProduct){
      for (let i = 0; i < this.itemLine.product.comboProducts.length; i++) {
        const childProd = this.itemLine.product.comboProducts[i];
        if ((this.type == 'STOCK_IN' && !childProd.childProduct.itemSerialRequiredWhenStockIn) || (this.type == 'STOCK_OUT' && !childProd.childProduct.itemSerialRequiredWhenStockOut)) {
          this.concurrentOutputSender(false);
          continue;
        }

        this.isSerialRequiredForAnyProduct = true;
        let checkForEmptySerialIndex = this.inventorySerials.findIndex(y => (y.serialNo == null || y.serialNo == "") && (y.productId == childProd.childProductId));
        if(checkForEmptySerialIndex > -1){
          this.focusItemSerial(checkForEmptySerialIndex);
          return;
        }

        if (this.inventorySerials.filter(x => x.productId == childProd.childProductId).length < (childProd.quantity * this.itemLine.quantity)) {
          this.inventorySerials.push(new Inventory(childProd.childProductId));
          this.focusItemSerial(this.inventorySerials.length-1);
          return;
        }
      }

    } else {

      if ((this.type == 'STOCK_IN' && !this.itemLine.product.itemSerialRequiredWhenStockIn) || (this.type == 'STOCK_OUT' && !this.itemLine.product.itemSerialRequiredWhenStockOut)) {
        this.concurrentOutputSender(false);
        return;
      }

      this.isSerialRequiredForAnyProduct = true;

      let checkForEmptySerialIndex = this.inventorySerials.findIndex(y => (y.serialNo == null || y.serialNo == "") && (y.productId == this.itemLine.productId));
      if(checkForEmptySerialIndex > -1){
        this.focusItemSerial(checkForEmptySerialIndex);
        return;
      }

      if (this.inventorySerials.length < this.itemLine.quantity){
        this.inventorySerials.push(new Inventory(this.itemLine.productId));
        this.focusItemSerial(this.inventorySerials.length-1);
        return;
      }

    }
    if (showAlertForNonSerialProducts){
      this.toastr.info('Serials count matches to quantity of product ' + this.itemLine.product.itemCode, 'Done!', {closeButton: true, timeOut: 4000});
      this.concurrentOutputSender(true);
    }
  }



  focusItemSerial(index){
    setTimeout(() => { // this will make the execution after the above boolean has changed
      document.getElementById('item-serial-' + this.itemLine.id + '-' + index).focus()
    }, 1);
  }

  removeSerialFromList(index) {
    this.inventorySerials.splice(index, 1);
    this.serialInputHandler();
  }

  concurrentOutputSender(quantityCompleted?: boolean){
    let itemLine = Object.assign({},this.itemLine);
    // itemLine.inventoryCartons = Object.assign([],this.inventoryCartons);
    itemLine.inventorySerials = Object.assign([],this.inventorySerials);
    itemLine.quantityCompleted = quantityCompleted;
    this.concurrentOutput.emit(itemLine);
  }

  save() {

    if (!ValidateSerials(this.itemLine, CleanObject(this.itemLine.inventorySerials), this.type, this.toastr)) {
      return;
    } else {
      this.inventorySerials = CleanObject(this.itemLine.inventorySerials);
      // let quantity = CountQuantity(this.inventorySerials, this.itemLine.product);
      // this.itemLine.inventoryCartons = this.inventoryCartons;
      // if(this.validateQuantity && this.itemLine.quantity && (this.itemLine.quantity != quantity)){
      //   this.toastr.warning("requested quantity is not equal to serial count", 'Warning!', {closeButton: true, timeOut: 4000});
      //   return;
      // }else {
      //   // this.itemLine.quantity = quantity;
      // }
      // this.serialListData.emit(this.inventoryCartons);
      this.itemLine.inventorySerials = this.inventorySerials;
      this.inventoryOutput.emit(this.itemLine);
    }
  }

  cancel() {
    this.inventoryOutput.emit(null);
  }

  showProductSwal(product, quantity){
    return swal({
      title: " Qty " + quantity ,
      html: "<h4 class='text-bold-600'>" + product.itemCode + " - " + product.itemName + "</h4><p>Popup close in 3 seconds.</p>",
      timer: 3000, showConfirmButton: false,
    })
  }
}

